import { rewriteAssetUrl } from 'utils/conversions/urls'

const getHero = (elements, index) => {
  let section = elements?.props?.content?.fields?.sections?.[index]?.fields?.sectionContents
  let image = getFile(section)
  if (!image && index < 2) {
    index++
    return getHero(elements, index)
  }
  return image
}

const getFile = (object) => {
  if (!Array.isArray(object)) return
  for (let section in object) {
    for (let key in object[section]?.fields) {
      if (object[section]?.fields[key]?.fields?.file?.url) {
        return rewriteAssetUrl(object[section]?.fields[key]?.fields?.file?.url)
      }
    }
  }
}

const getHeroImage = (children) => {
  let heroLogo = getHero(children, 0)
  let logo = rewriteAssetUrl(
    '//images.ctfassets.net/80jeq2aeq4l1/6buj5UWLThOWWXMbxEuIDw/2590f50e5e42ff83db13094265b703ef/2022_Achieve_Primary-Logo_Gradient_RGB.png'
  )
  return heroLogo ? heroLogo : logo
}

const getArticleImage = (elements) => {
  const logoDefault = rewriteAssetUrl(
    '//images.ctfassets.net/80jeq2aeq4l1/1y1zI2cYKh97gI7q7Ud4Cz/6a9732332498bb4eb7e62c48acb9d21a/1280x720_AchieveLogo.png'
  )
  return (
    elements?.props?.children?.[0]?.props?.content?.items?.[0]?.fields?.mainImage?.fields
      ?.mediaContent?.fields?.file?.url ??
    elements?.props?.children?.[0]?.props?.content?.items?.[0]?.fields?.mainImage?.fields?.thumbnail
      ?.fields?.file?.url ??
    logoDefault
  )
}

const getPressImage = (elements) => {
  const logoDefault = rewriteAssetUrl(
    '//images.ctfassets.net/80jeq2aeq4l1/1y1zI2cYKh97gI7q7Ud4Cz/6a9732332498bb4eb7e62c48acb9d21a/1280x720_AchieveLogo.png'
  )
  return elements?.props?.content?.items?.[0]?.fields?.mainImage?.fields?.file?.url ?? logoDefault
}

export { getHeroImage, getArticleImage, getPressImage }
