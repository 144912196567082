const FFAM = {
  SPECIAL_MESSAGE_COOKIE_NAME: 'FFAM-SPECIAL-MESSAGE',
}
Object.freeze(FFAM)

const FFAM_BANNER_URLS = [
  'https://www.freedomplus.com',
  'https://www.freedomplus.com/',
  'https://freedomplus.com',
  'https://freedomplus.com/',

  'https://www.achievepersonalloans.com',
  'https://www.achievepersonalloans.com/',
  'https://achievepersonalloans.com',
  'https://achievepersonalloans.com/',

  'https://achievepersonaloans.com',
  'https://achievepersonaloans.com/',
  'https://www.achievepersonaloans.com',
  'https://www.achievepersonaloans.com/',
]

const FFAM_BANNER_QUERY_REFERRERS = [
  'freedomplus.com',
  'achievepersonalloans.com',
  'achievepersonaloans.com',
]

const isFFAMDisabled = () => {
  // Disable Lendage past 01/16/2023
  const now = new Date()
  const lendageLastDay = new Date(Date.UTC(2023, 0, 16, 23, 59))
  return now > lendageLastDay
}

export { FFAM, FFAM_BANNER_URLS, FFAM_BANNER_QUERY_REFERRERS, isFFAMDisabled }
