import { createContext, useReducer } from 'react'

const HeaderContext = createContext()

const headerReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_NAV_ITEM_INDEX':
      return {
        ...state,
        activeItemIndex: action.itemIndex,
      }
    case 'SET_MOBILE_NAV_OPEN':
      return {
        ...state,
        mobileNavOpen: action.open,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const HeaderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(headerReducer, {
    activeItemIndex: undefined,
    mobileNavOpen: false,
  })
  return (
    <HeaderContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export { HeaderProvider, HeaderContext }
