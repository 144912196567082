import { BLOG_ROUTE, CONSUMER_NAME_ROUTE, LEARN_ROUTE, PRESS_ROUTE } from 'constants/navigation'
import { ogIncludeMetaTypeArray, ogIncludeMetaTypeArticleArray } from 'constants/metaTags'
import { getArticleImage, getHeroImage, getPressImage } from './file'

const getTwitterImage = (route, children) => {
  switch (route) {
    case 'home-equity-loan':
    case 'personal-loans':
    case 'resolve-debt':
      return 'placeholder'
    case 'learn':
      return getArticleImage(children)
    case 'about':
      return getPressImage(children)
    default:
      return getHeroImage(children)
  }
}

const getOgTypeMetaTag = (route) => {
  if (ogIncludeMetaTypeArticleArray.includes(route)) {
    return <meta name="og:type" content="article" />
  }

  if (ogIncludeMetaTypeArray.includes(route)) {
    return <meta name="og:type" content="website" />
  }

  return null
}

const getPaginatedPageCanonicalLink = (link) => {
  if (!link) return
  const canonicalLinkQueryIndex = link.indexOf('?')
  return canonicalLinkQueryIndex !== -1 ? link.substring(0, canonicalLinkQueryIndex) : link
}

const getPageMetaTitleForRoute = (router, pageMetaTitle, pageTitle) => {
  const path = router.pathname.split('/[')?.[0]
  let title = pageMetaTitle
  if (path == '/404') {
    return 'Page Not Found'
  }
  if (path == '/500') {
    return 'Internal Server Error'
  }
  if (pageTitle)
    switch (path) {
      case LEARN_ROUTE:
      case CONSUMER_NAME_ROUTE:
      case BLOG_ROUTE:
      case PRESS_ROUTE:
        title = pageTitle
        break
    }
  return title
}

export {
  getTwitterImage,
  getOgTypeMetaTag,
  getPaginatedPageCanonicalLink,
  getPageMetaTitleForRoute,
}
