export const ogIncludeMetaTypeArray = [
  'about',
  'home-equity-loan',
  'personal-loans',
  'resolve-debt',
  'learn',
  'dollars-sense',
]

export const ogIncludeMetaTypeArticleArray = ['learn', 'dollars-sense', 'blog']
