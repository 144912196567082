import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import 'whatwg-fetch'

const ApolloClientProvider = ({ children }) => {
  const http = new HttpLink({
    uri: '/api/contentful/graphql',
  })

  const link = ApolloLink.from([http])

  const cache = new InMemoryCache()

  const apolloClient = new ApolloClient({
    link,
    cache,
  })
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
export default ApolloClientProvider
